import { Subject } from 'rxjs';

export interface PopupInput<Input, Output> {
  data: Input,
  callback: (output?: Output) => void
}

export abstract class BasePopupService<Input, Output> {

  onOpen = new Subject<PopupInput<Input, Output>>();

  async show(data: Input): Promise<Output | undefined> {
    console.log('input: ', data);
    return new Promise(resolve => {
      const callback = (response?: Output) => resolve(response);
      this.onOpen.next({ data, callback });
    });
  }
}
